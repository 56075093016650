exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-adb-js": () => import("./../../../src/pages/adb.js" /* webpackChunkName: "component---src-pages-adb-js" */),
  "component---src-pages-apply-now-js": () => import("./../../../src/pages/apply-now.js" /* webpackChunkName: "component---src-pages-apply-now-js" */),
  "component---src-pages-campus-js": () => import("./../../../src/pages/campus.js" /* webpackChunkName: "component---src-pages-campus-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-csr-js": () => import("./../../../src/pages/csr.js" /* webpackChunkName: "component---src-pages-csr-js" */),
  "component---src-pages-data-deletion-js": () => import("./../../../src/pages/data-deletion.js" /* webpackChunkName: "component---src-pages-data-deletion-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-merchant-agreement-js": () => import("./../../../src/pages/merchant-agreement.js" /* webpackChunkName: "component---src-pages-merchant-agreement-js" */),
  "component---src-pages-merchants-js": () => import("./../../../src/pages/merchants.js" /* webpackChunkName: "component---src-pages-merchants-js" */),
  "component---src-pages-mini-pos-js": () => import("./../../../src/pages/mini-pos.js" /* webpackChunkName: "component---src-pages-mini-pos-js" */),
  "component---src-pages-my-pos-js": () => import("./../../../src/pages/my-pos.js" /* webpackChunkName: "component---src-pages-my-pos-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pk-404-js": () => import("./../../../src/pages/pk/404.js" /* webpackChunkName: "component---src-pages-pk-404-js" */),
  "component---src-pages-pk-about-us-js": () => import("./../../../src/pages/pk/about-us.js" /* webpackChunkName: "component---src-pages-pk-about-us-js" */),
  "component---src-pages-pk-careers-js": () => import("./../../../src/pages/pk/careers.js" /* webpackChunkName: "component---src-pages-pk-careers-js" */),
  "component---src-pages-pk-contact-us-js": () => import("./../../../src/pages/pk/contact-us.js" /* webpackChunkName: "component---src-pages-pk-contact-us-js" */),
  "component---src-pages-pk-faqs-js": () => import("./../../../src/pages/pk/faqs.js" /* webpackChunkName: "component---src-pages-pk-faqs-js" */),
  "component---src-pages-pk-index-js": () => import("./../../../src/pages/pk/index.js" /* webpackChunkName: "component---src-pages-pk-index-js" */),
  "component---src-pages-pk-merchants-js": () => import("./../../../src/pages/pk/merchants.js" /* webpackChunkName: "component---src-pages-pk-merchants-js" */),
  "component---src-pages-pk-mini-pos-js": () => import("./../../../src/pages/pk/mini-pos.js" /* webpackChunkName: "component---src-pages-pk-mini-pos-js" */),
  "component---src-pages-pk-privacy-policy-js": () => import("./../../../src/pages/pk/privacy-policy.js" /* webpackChunkName: "component---src-pages-pk-privacy-policy-js" */),
  "component---src-pages-pk-security-js": () => import("./../../../src/pages/pk/security.js" /* webpackChunkName: "component---src-pages-pk-security-js" */),
  "component---src-pages-pk-terms-and-conditions-js": () => import("./../../../src/pages/pk/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-pk-terms-and-conditions-js" */),
  "component---src-pages-pk-test-js": () => import("./../../../src/pages/pk/test.js" /* webpackChunkName: "component---src-pages-pk-test-js" */),
  "component---src-pages-play-4-a-child-js": () => import("./../../../src/pages/play4aChild.js" /* webpackChunkName: "component---src-pages-play-4-a-child-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-scholarship-js": () => import("./../../../src/pages/scholarship.js" /* webpackChunkName: "component---src-pages-scholarship-js" */),
  "component---src-pages-school-js": () => import("./../../../src/pages/school.js" /* webpackChunkName: "component---src-pages-school-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-service-catalog-js": () => import("./../../../src/pages/service-catalog.js" /* webpackChunkName: "component---src-pages-service-catalog-js" */),
  "component---src-pages-service-level-agreement-js": () => import("./../../../src/pages/service-level-agreement.js" /* webpackChunkName: "component---src-pages-service-level-agreement-js" */),
  "component---src-pages-society-js": () => import("./../../../src/pages/society.js" /* webpackChunkName: "component---src-pages-society-js" */),
  "component---src-pages-stay-alert-js": () => import("./../../../src/pages/stay-alert.js" /* webpackChunkName: "component---src-pages-stay-alert-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-ng-js": () => import("./../../../src/pages/terms-ng.js" /* webpackChunkName: "component---src-pages-terms-ng-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

